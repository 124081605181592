import { SectionHeading } from '@/components/atomic/atoms';
import { CardListMobile } from '@/components/atomic/molecules';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const HaflaBlogsMobileOrganism = ({ posts = [] }) => (
  <div className='bg-neutral pt-4 pb-10'>
    <SectionHeading
      title='Hafla Blog'
      underline
    />
    <CardListMobile
      {...{
        cardList: posts,
        imageBasePath: '',
        isViewButtonVisible: true,
        viewButtonLink: getPageURL({
          pageName: ROUTE_CONFIG.BLOG.label
        })
      }}
    />
  </div>
);

export default HaflaBlogsMobileOrganism;
