import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  captureGTMEventCTAClick,
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME
} from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const BecomePartnerMobile = () => {
  const router = useRouter();
  return (
    <div className='relative w-full bg-become-partner-background py-4'>
      <div className='inset-0 flex flex-col lg:flex-row items-center justify-center'>
        <Image
          alt='Hands with Puzzle'
          className='w-16 sm:w-24 md:w-32 lg:w-40 h-auto'
          height={154}
          src={`${staticMediaStoreBaseURL}/home-page/become-partner.svg`}
          width={220}
        />
        <div className='flex flex-col justify-center items-center text-center p-4 sm:p-6 md:p-8 lg:flex-1'>
          <div className='text-2xl font-Montserrat font-bold text-white'>
            Become a Partner
          </div>
          <div className='text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-Montserrat font-normal leading-5 text-white mb-4 sm:mb-2'>
            3 easy steps to join the Hafla!
          </div>
          <div
            className={`flex items-center justify-center px-5 py-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition`}
            onClick={() => {
              captureGTMEventCTAClick({
                ctaName: GTM_EVENTS_CTA_NAME.BECOME_PARTNER,
                pageName: PAGE_NAME.HOME_PAGE.label
              });
              router.push(
                getPageURL({
                  pageName: ROUTE_CONFIG.PARTNER_BASIC_INFO.label
                })
              );
            }}
          >
            <Text
              {...{
                className:
                  'font-google-basic text-10px uppercase tracking-wider mr-2 text-black font-normal',
                content: 'Start here'
              }}
            />
            <Image
              alt='arrow right'
              height={4}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
              width={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomePartnerMobile;
