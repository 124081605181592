import Image from 'next/image';
import { useState } from 'react';

import {
  ConfirmationModal,
  SpinnerWithInput,
  Text
} from '@/components/atomic/atoms';
import {
  HostCartProductNotes,
  LineItemMedia
} from '@/components/atomic/molecules';
import { PRODUCT_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME } from '@/lib/gtm';
import { getPriceLabel } from '@/services/hostCart.service';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const RemoveItemIcon = ({ onClick: deleteItem, productStatus }) => {
  const imageSrc = `${staticMediaStoreBaseURL}/icons/${
    productStatus === PRODUCT_STATUS.UNPUBLISHED
      ? 'delete-icon-red.svg'
      : 'delete-icon-gray.svg'
  }`;
  return (
    <div className='w-7 h-7'>
      <Image
        {...{
          alt: 'delete',
          className: 'rounded-lg cursor-pointer',
          height: 28,
          onClick: deleteItem,
          src: imageSrc,
          width: 28
        }}
      />
    </div>
  );
};

const CartItemBody = ({
  availableDiscount,
  categories,
  id,
  isCartEditable,
  isSpinnerInputReadOnly,
  maxBookable,
  minBookable,
  minPrice,
  price,
  priceLabel,
  priceLabelClass,
  productName,
  productType,
  quantity,
  updateCartItemDetail
}) => (
  <div className='flex flex-col gap-1 md:gap-4'>
    <div className='flex gap-3 md:gap-6'>
      <SpinnerWithInput
        {...{
          availableDiscount,
          categories,
          disabled: !isCartEditable || !isSpinnerInputReadOnly,
          id,
          minPrice,
          pageName: PAGE_NAME.HOST_CART.label,
          price,
          productName,
          productType,
          quantity,
          range: { min: minBookable, max: maxBookable },
          updateCartItemDetail
        }}
      />
      <Text
        {...{
          className: `self-center text-sm font-medium ${priceLabelClass}`,
          content: priceLabel
        }}
      />
    </div>
  </div>
);

const CartItemRowMobileOrganism = ({
  cartItemTitle,
  cartItemTitleClass,
  deleteItem,
  derivedValues: { unitSellingPrice } = {},
  id,
  isCartEditable = true,
  isSpinnerInputReadOnly,
  itemMediaForCarousel,
  lastCartItem,
  priceLabelClass,
  product: {
    maxBookable,
    minBookable,
    name: productName,
    price,
    slug,
    status: productStatus,
    type: productType
  },
  productNotes,
  quantity,
  unitListedPrice,
  updateCartItemDetail
}) => {
  const { availableDiscount, categories, minPrice = 0 } = price;

  const [showModal, setShowModal] = useState(false);
  const priceLabel = getPriceLabel({
    minPrice,
    productType,
    quantity,
    unitListedPrice,
    unitSellingPrice
  });

  return (
    <div className={`${lastCartItem ? '' : 'border-b pb-3'} border-platinum`}>
      <div className='flex gap-3 md:gap-4 font-medium'>
        <div className='rounded-2.5'>
          <LineItemMedia {...{ itemMediaForCarousel, isHostCart: true }} />
        </div>
        <div className='flex flex-1 justify-between self-baseline'>
          <a
            href={getPageURL({
              pageName: ROUTE_CONFIG.PDP.label,
              pathParams: {
                slug
              }
            })}
          >
            <Text
              {...{
                className: `text-sm md:text-lg flex-1 md:mt-1 font-medium ${cartItemTitleClass}`,
                content: productName
              }}
            />
          </a>
          {isCartEditable && (
            <RemoveItemIcon
              {...{ onClick: () => setShowModal(true), productStatus }}
            />
          )}
        </div>
      </div>
      <CartItemBody
        {...{
          availableDiscount,
          cartItemTitle,
          cartItemTitleClass,
          categories,
          id,
          isCartEditable,
          isSpinnerInputReadOnly,
          maxBookable,
          minBookable,
          minPrice,
          price,
          priceLabel,
          priceLabelClass,
          productName,
          productType,
          quantity,
          unitListedPrice,
          unitSellingPrice,
          updateCartItemDetail
        }}
      />
      <HostCartProductNotes {...{ productNotes }} />
      {showModal && (
        <ConfirmationModal
          {...{
            modalText: 'Are you sure you want to remove this item?',
            operation: () => deleteItem({ cartItemId: id }),
            setShowModal
          }}
        />
      )}
    </div>
  );
};

export default CartItemRowMobileOrganism;
