import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CTA } from '@/components/atomic/atoms';
import { RequestForQuoteModal } from '@/components/atomic/molecules';
import { convertToDateFormat } from '@/lib/time';
import { yupValidator } from '@/lib/yup-validator';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getAuth } from '@/services/identity.service';

const RequestPendingItemListOrganism = ({
  CartItemRow,
  cartItemTitleClass,
  checkoutEventList,
  deleteItem,
  isCartEditable = true,
  isRequestForQuote,
  isValidForRequestForQuote,
  itemList = [],
  onClickRequestForQuote,
  priceLabelClass,
  setIsRequestForQuote,
  updateCartItemDetail,
  userCart,
  userEventSummary: { userEventDetails: [userEventDetails] = [] } = {}
}) => {
  const router = useRouter();
  const isUserLoggedIn = Boolean(!getAuth()?.isGuest);
  const { email, mobile, name } = userCart || {};
  const {
    checkoutEvent: { id: checkoutEventId } = {},
    eventDate,
    eventTitle
  } = userEventDetails || {};

  const yupResolver = yupValidator(
    yup.object().shape({
      checkoutEventId: yup.string().required('Event type is mandatory.'),
      eventTitle: yup.string().required('Event Title is mandatory.')
    })
  );
  const defaultFormattedEventDate = convertToDateFormat(
    eventDate || Date.now(),
    'MM/DD/YYYY'
  );

  const {
    register,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      checkoutEventId,
      customerEmail: email,
      customerMobile: mobile,
      customerName: name,
      eventDate: defaultFormattedEventDate,
      eventTitle
    }
  });

  const [selectedEventDate, setSelectedEventDate] = useState(
    defaultFormattedEventDate
  );

  return (
    <div className='border border-red-500 px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-3'>
      {itemList.map((items, index) => (
        <CartItemRow
          key={index}
          {...{
            ...items,
            cartItemTitleClass,
            deleteItem,
            isCartEditable,
            priceLabelClass,
            updateCartItemDetail
          }}
        />
      ))}
      {isCartEditable && (
        <div className='flex justify-end'>
          <CTA
            {...{
              buttonColor:
                'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
              children: 'Request for quote',
              className: 'text-sm font-medium w-40 py-2',
              disabled: isValidForRequestForQuote,
              onClick: () => {
                if (isUserLoggedIn) {
                  setIsRequestForQuote(true);
                } else {
                  router.replace(
                    getPageURL({
                      pageName: ROUTE_CONFIG.LOGIN.label
                    })
                  );
                }
              }
            }}
          />
        </div>
      )}
      {isRequestForQuote && (
        <RequestForQuoteModal
          {...{
            checkoutEventList,
            clearErrors,
            control,
            errors,
            eventDate: selectedEventDate,
            getValues,
            handleSubmit,
            isRequestForQuote,
            onClickRequestForQuote,
            register,
            setIsRequestForQuote,
            setSelectedEventDate,
            setValue
          }}
        />
      )}
    </div>
  );
};

export default RequestPendingItemListOrganism;
